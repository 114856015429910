import "./reset.css"
import "./grid.css"

import "./google-fonts-1685709575860.css"

/* Components */
import "./announcements.css"
import "./attachments.css"
import "./badges.css"
import "./boxes.css"
import "./buttons.css"
import "./calendar.css"
import "./cards.css"
import "./contacts.css"
import "./details.css"
import "./documents.css"
import "./filter.css"
import "./forms.css"
import "./functional.css"
import "./header.css"
import "./hello.css"
import "./icons.css"
import "./inverted.css"
import "./login.css"
import "./messages.css"
import "./objects.css"
import "./pagination.css"
import "./start.css"
import "./strengths.css"
import "./submenu.css"
import "./tables.css"
import "./textlinks.css"
import "./typography.css"

/* Variables, base styles */
import "./main.css"

// Polyfills
import "./lib/form-request-submit-polyfill.js"

// Modals
import "dialog-polyfill/dialog-polyfill.css"
import "./modals.css" // Has to come after dialog-polyfill.css
import { initModals } from "./modals.js"

// JS
import hoverintent from "hoverintent"
import { addInlineForm } from "./addInlineForm.js"
import { setDetailsHeights } from "./details.js"
import { initDocuments } from "./documents.js"
import { csrftoken, onReady } from "./utils.js"
import { initAutosubmit } from "./autosubmit.js"
import { initAnnouncements } from "./announcements.js"
import { initCalendars } from "./calendar.js"
import { initStrengths } from "./strengths.js"

onReady(() => {
  const cb = document.querySelector("#menu-open-cb")
  const header = document.querySelector(".header")
  if (cb && header) {
    hoverintent(
      header,
      () => (cb.checked = true),
      () => null,
    ).options({ interval: 250 })
  }
})

onReady(() => {
  /* Run it */
  setDetailsHeights("details")
})

onReady(initModals)
onReady(initDocuments)
onReady(initAutosubmit)
onReady(initAnnouncements)
onReady(initCalendars)
onReady(initStrengths)

onReady(() => {
  /* Maybe we can drop this when .box:has(.box__link) > .box__body becomes available */
  document
    .querySelectorAll(".box__link")
    .forEach((el) => el.parentNode.classList.add("box--has-link"))
})

onReady(() => {
  document.body.addEventListener("click", (e) => {
    const setStarred = e.target.closest("[data-set-starred]")
    const now = e.target.closest("[data-starred]")
    if (setStarred && now) {
      e.preventDefault()
      const fd = new FormData()
      fd.append("starred", setStarred.dataset.setStarred)
      fetch(now.href, {
        credentials: "same-origin",
        headers: {
          "X-CSRFToken": csrftoken(),
        },
        method: "post",
        body: fd,
      })
        .then((r) => r.json())
        .then((data) => {
          console.log({ data })
          now.dataset.starred = setStarred.dataset.setStarred
        })
    }
  })
})

onReady(() => {
  document.body.addEventListener("click", (e) => {
    const button = e.target.closest("[data-add-inline]")
    if (button) {
      e.preventDefault()
      addInlineForm(button.dataset.addInline)
    }
  })
})
