export function addInlineForm(slug) {
  const totalForms = document.querySelector(`#id_${slug}-TOTAL_FORMS`),
    newId = parseInt(totalForms.value, 10) || 0

  totalForms.value = newId + 1

  const empty = document.querySelector(`#${slug}-empty`)
  let form = document.createElement("div")
  // This *should* be quite safe. It may happen (improbable9 that __prefix__
  // appears somewhere in user-entered text the regex here is only applied to
  // the empty form which only holds default values generated by the backend
  // code.
  form.innerHTML = empty.textContent
    .replace(/-__prefix__-/g, `-${newId}-`)
    .trim()
  form = form.firstChild
  form.setAttribute("id", `${slug}-${newId}`)

  const nodes = empty.parentNode.querySelectorAll(`[id|=${slug}]`)
  nodes[nodes.length - 1].insertAdjacentElement("afterend", form)

  return false
}
