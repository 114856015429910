import { Fragment, h, render } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"

import {
  addDays,
  addMonths,
  areIntervalsOverlapping,
  differenceInCalendarDays,
  differenceInYears,
  endOfDay,
  format as _format,
  getDaysInMonth,
  nextMonday,
  parseISO,
  startOfMonth,
  startOfToday,
} from "date-fns"
import { de } from "date-fns/locale"

const { interpolate, ngettext } = window

const format = (date, fmt) => _format(date, fmt, { locale: de })
const previousMonday = (date) => addDays(nextMonday(date), -7)

const WEEKDAYS = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

const parseDates = (events) =>
  events.map((event) => {
    // differenceInCalendarDays requires an object with a start and an end key.
    const start = parseISO(event.start)
    const end = endOfDay(event.end ? parseISO(event.end) : start)
    return {
      ...event,
      start,
      end,
    }
  })

const boundingInterval = (events) => {
  const today = startOfToday()
  let start = today,
    end = today
  for (let event of events) {
    if (event.start < start) start = event.start
    if (event.end > end) end = event.end
  }

  return { today, start, end }
}

const groupByUser = (events) => {
  const byUser = {}
  events.forEach((event) => {
    if (!(event.userId in byUser)) {
      byUser[event.userId] = [[]]
    }
    for (let row of byUser[event.userId]) {
      if (row.find((e) => areIntervalsOverlapping(event, e))) {
        continue
      }

      row.push(event)
      return
    }

    byUser[event.userId].push([event])
  })
  return byUser
}

const weeks = (firstMonday, lastMonday) => {
  let weeks = []
  for (
    let monday = firstMonday;
    monday <= lastMonday;
    monday = addDays(monday, 7)
  ) {
    weeks.push(monday)
  }
  return weeks
}

const groupEventsByDayIndex = (events) => {
  const obj = {}
  events.forEach((event) => {
    for (let day of dayRange(event.start, event.end)) {
      const index = dayIndex(day)
      if (!(index in obj)) obj[index] = []
      obj[index].push(event)
    }
  })
  return obj
}

const thisMonth = () => startOfMonth(new Date())

const classnames = (...iterable) => iterable.filter((c) => c).join(" ")

const dayIndex = (d) =>
  10000 * d.getFullYear() + 100 * (d.getMonth() + 1) + d.getDate()

const dayRange = (start, end) => {
  let day = start
  const range = [day]
  for (; ; true) {
    day = addDays(day, 1)
    if (day <= end) {
      range.push(day)
    } else {
      return range
    }
  }
}

const Month = ({ month, eventsByDayIndex }) => {
  const days = Array.from({ length: getDaysInMonth(month) }, (x, i) =>
    addDays(month, i),
  )
  const padding = (month.getDay() + 6) % 7

  const mondayIndex = dayIndex(previousMonday(startOfToday()))
  const nextMondayIndex = dayIndex(nextMonday(startOfToday()))

  return (
    <div class="usercal__month">
      {WEEKDAYS.map((weekday, idx) => (
        <div key={idx} class="usercal__cell usercal__cell--weekday">
          <span class="usercal__text">{weekday}</span>
        </div>
      ))}
      {Array.from({ length: padding }).map((_, idx) => (
        <div key={idx} class="usercal__space" />
      ))}
      {days.map((day) => {
        const index = dayIndex(day)
        const dayEvents = eventsByDayIndex[index] || []
        const types = [...new Set(dayEvents.map((event) => event.type))]
        const eventsClasses = types.map((type) => `usercal__cell--${type}`)

        return (
          <div
            key={day}
            class={classnames(
              "usercal__cell",
              index >= mondayIndex && index < nextMondayIndex
                ? "usercal__cell--thisweek"
                : "",
              dayEvents.length && "usercal__cell--events",
              ...eventsClasses,
            )}
          >
            <span class="usercal__text">
              {types.map((type, idx) => (
                <span
                  key={idx}
                  class={classnames("usercal__icon", `type--${type}`)}
                />
              ))}
              {day.getDate()}
            </span>
            {dayEvents.length ? <Popup dayEvents={dayEvents} /> : null}
          </div>
        )
      })}
    </div>
  )
}

const Popup = ({ dayEvents }) => (
  <div class="usercal__popup">
    {dayEvents.map((event, idx) => (
      <div key={idx} class={`usercal__event type--${event.type}`}>
        <h4>
          {event.typeLabel || event.type}: {event.title}
        </h4>
        <p>
          <Duration event={event} />
        </p>
      </div>
    ))}
  </div>
)

const UserCalendar = () => {
  const [month, setMonth] = useState(thisMonth)
  const nextMonth = addMonths(month, 1)

  const [events] = useState(() => {
    const el = document.getElementById("user-events")
    const events = parseDates(JSON.parse(el.textContent))
    let { today, start, end } = boundingInterval(events)
    start = startOfMonth(start)
    end = startOfMonth(end)
    return {
      today,
      start,
      end,
      byDayIndex: groupEventsByDayIndex(events),
    }
  })

  // console.log(events)

  return (
    <div class="usercal">
      <div class="grid grid--gx">
        <div class="cell md-6">
          <div class="usercal__title">{format(month, "MMMM yyyy")}</div>
          <Month month={month} eventsByDayIndex={events.byDayIndex} />
        </div>
        <div class="cell md-6">
          <div class="usercal__title">{format(nextMonth, "MMMM yyyy")}</div>
          <Month month={nextMonth} eventsByDayIndex={events.byDayIndex} />
        </div>
      </div>
      <div class="box__toolbar">
        <button
          onClick={() => setMonth(addMonths(month, -1))}
          disabled={month <= events.start}
          class="box__tool box__tool--left"
        />
        <button
          onClick={() => setMonth(addMonths(month, 1))}
          disabled={month >= events.end}
          class="box__tool box__tool--right"
        />
      </div>
    </div>
  )
}

const addDayColumn = (events, start) =>
  events.map((event) => ({
    ...event,
    dayColumn: differenceInCalendarDays(event.start, start),
  }))

const Calendar = ({ absenceCreateUrl }) => {
  const [events] = useState(() => {
    const el = document.getElementById("events")
    let { users, events } = JSON.parse(el.textContent)
    events = parseDates(events)

    let { today, start, end } = boundingInterval(events)
    start = previousMonday(start)
    end = previousMonday(end)

    events = addDayColumn(events, start)

    const types = new Set()
    events.forEach((event) => types.add(event.type))

    return {
      events,
      today,
      todayColumn: differenceInCalendarDays(today, start),
      types,
      users,
      weeks: weeks(start, end),
    }
  })
  const [meta] = useState(() => {
    const el = document.getElementById("meta")
    return JSON.parse(el.textContent)
  })

  const [type, setType] = useState("")
  const byUser = groupByUser(
    type ? events.events.filter((event) => event.type === type) : events.events,
  )
  const users = events.users.filter((user) => byUser[user.id])

  // console.log(events)
  // console.log({ byUser, type })

  const rows = Object.values(byUser).flat()

  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return

    const applyHeight = () => {
      const y = window.scrollY + ref.current.getBoundingClientRect().top
      ref.current.style.maxHeight = `calc(95vh - ${y}px)`
    }

    applyHeight()
    window.addEventListener("resize", applyHeight)
    return () => {
      window.removeEventListener("resize", applyHeight)
    }
  }, [ref])

  return (
    <div class="cal-wrapper">
      <h1>
        Kalender
        <a
          href={absenceCreateUrl}
          class="button button--small"
          data-toggle="ajaxmodal"
        >
          + Eintrag hinzufügen
        </a>
      </h1>
      <div
        class="cal"
        style={{ "--rows": rows.length, "--days": events.weeks.length * 7 }}
      >
        <div class="cal__types">
          {meta.types.map((t, idx) =>
            events.types.has(t.type) ? (
              <span
                key={idx}
                class={classnames(
                  "cal__type",
                  `type--${t.type}`,
                  type && type !== t.type ? "inactive" : type ? "active" : "",
                )}
                onClick={() => setType(type === t.type ? "" : t.type)}
              >
                {t.legend}
              </span>
            ) : null,
          )}
        </div>
        <div class="cal__calendar" ref={ref}>
          <CalendarUsers users={users} byUser={byUser} />
          <div class="cal__events">
            <CalendarHeader weeks={events.weeks} />
            <div
              class="cal__today"
              style={{ "--today": events.todayColumn }}
              title="Heute"
            />
            {null in byUser
              ? byUser[null].map((row, idx) => (
                  <CalendarRow
                    key={idx}
                    events={row}
                    isFirst={!idx}
                    isZero={true}
                  />
                ))
              : null}
            {users.map((user) => (
              <Fragment key={user}>
                {byUser[user.id].map((row, idx) => (
                  <CalendarRow key={idx} events={row} isFirst={!idx} />
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const CalendarUsers = ({ users, byUser }) => (
  <div class="cal__users">
    {null in byUser ? (
      <div
        class="cal__row cal__row--zero"
        style={{ "--height": byUser[null].length }}
      >
        <div class="cal__user">Veranstaltungen</div>
      </div>
    ) : null}
    {users.map((user) => (
      <div
        key={user}
        class="cal__row"
        style={{ "--height": byUser[user.id].length }}
      >
        <a href={`/team/users/${user.id}/`} class="cal__user">
          {user.full_name}
        </a>
      </div>
    ))}
  </div>
)

const createIfChanged = () => {
  let last = null
  return (value) => {
    if (last == value) {
      return null
    }
    last = value
    return value
  }
}

const CalendarHeader = ({ weeks }) => {
  const yearIfChanged = createIfChanged()
  const monthIfChanged = createIfChanged()

  return (
    <div class="cal__header-row">
      {weeks.map((week, idx) => {
        return (
          <div
            key={week}
            class="cal__cell"
            style={{ "--start": idx * 7, "--days": 7 }}
          >
            <div class="cal__header-cell">
              {yearIfChanged(format(week, "yyyy"))}
              <br />
              {monthIfChanged(format(week, "MMMM"))}
              <br />
              {format(week, "d.")}
              <br />
              KW{format(week, "I")}
            </div>
          </div>
        )
      })}
    </div>
  )
}

const CalendarRow = ({ events, isFirst, isZero }) => {
  return (
    <div
      class={classnames(
        "cal__row",
        isFirst ? "" : "cal__row--no-borders",
        isZero ? "cal__row--zero" : "",
      )}
    >
      {events.map((event, idx) => {
        const days = differenceInCalendarDays(event.end, event.start)
        return (
          <a
            key={idx}
            href={event.url}
            data-toggle={event.modal ? "ajaxmodal" : ""}
            class={classnames(
              "cal__cell",
              `type--${event.type} ${
                event.subtype ? `type--${event.subtype}` : ""
              }`,
            )}
            style={{
              "--start": event.dayColumn,
              "--days": days,
            }}
          >
            <div class="cal__event">{days >= 3 ? event.title : ""}</div>
            <div class="cal__popup">
              <h4>{event.typeLabel || event.type}</h4>
              <p>
                <Duration event={event} />
              </p>
              {event.dateForYears ? (
                <Years start={parseISO(event.dateForYears)} end={event.start} />
              ) : null}
              <p>{event.title}</p>
            </div>
          </a>
        )
      })}
    </div>
  )
}

const Duration = ({ event }) => {
  let fS = format(event.start, "d.M.yyyy")
  const fE = format(event.end, "d.M.yyyy")
  if (fS != fE) {
    fS += ` - ${fE}`
  }
  return fS
}

const Years = ({ start, end }) => {
  const years = differenceInYears(end, start)
  if (years > 500) return
  return <p>{interpolate(ngettext("%s year", "%s years", years), [years])}</p>
}

export function initCalendars() {
  document.querySelectorAll("[data-user-calendar]").forEach((el) => {
    render(<UserCalendar />, el)
  })

  document.querySelectorAll("[data-calendar]").forEach((el) => {
    render(<Calendar absenceCreateUrl={el.dataset.absenceCreateUrl} />, el)
  })
}
