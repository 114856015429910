import { h, render } from "preact"
import { useState } from "preact/hooks"

export function initStrengths() {
  document.querySelectorAll("[data-strengths]").forEach((el) => {
    const script = el.querySelector("script")
    render(
      <Strengths
        title={el.dataset.title}
        strengths={JSON.parse(script.textContent)}
      />,
      el,
    )
  })
}

const Strengths = ({ title, strengths }) => {
  const [focus, setFocus] = useState(null)

  return (
    <div class="box__body strengths">
      <h2>{title}</h2>
      <p>
        {strengths.map((row, idx) => (
          <span
            key={idx}
            class="badge badge--blue badge--strength"
            onClick={() => setFocus(row)}
          >
            {row.strength}
          </span>
        ))}
      </p>
      {focus ? (
        <div class="strength" onClick={() => setFocus(null)}>
          <h2>
            <strong>Stärke:</strong> {focus.strength}
          </h2>
          <p>{focus.users.join(", ")}</p>
          {/*
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault()
            }}
            class="textlink textlink--strength"
          >
            Zurück zur Übersicht
          </a>
          */}
        </div>
      ) : null}
    </div>
  )
}
