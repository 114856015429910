export function onReady(fn) {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    // call on next available tick
    setTimeout(fn, 1)
  } else {
    document.addEventListener("DOMContentLoaded", fn)
  }
}

export function firstMatch(str, regexp) {
  const matches = str.match(regexp)
  return matches ? matches[1] : ""
}

export const csrftoken = () =>
  firstMatch(document.cookie, /\bcsrftoken=(\w+?)\b/)
