import dialogPolyfill from "dialog-polyfill"

async function showModalFromURL(url) {
  const response = await fetch(url, {
    credentials: "same-origin",
    headers: { "x-fetch": "true" },
  })
  const html = await response.text()

  document.querySelectorAll("dialog._smfu").forEach((el) => {
    el.parentNode.removeChild(el)
  })

  const dialog = document.createElement("dialog")
  dialog.classList.add("fixed")
  dialog.classList.add("_smfu")
  dialog.innerHTML = html
  document.body.appendChild(dialog)
  dialogPolyfill.registerDialog(dialog)
  dialog.showModal()

  dialog.addEventListener("submit", async (e) => {
    const form = e.target.closest("form")
    console.log("submit", e, form, form.method.toLowerCase())
    if (form && form.method.toLowerCase() === "post") {
      e.preventDefault()

      console.log("Prevented the default behavior")

      const fd = new FormData(form)
      const response = await fetch(form.action, {
        credentials: "same-origin",
        headers: { "x-fetch": "true" },
        method: "post",
        body: fd,
      })
      // 201 CREATED, 202 ACCEPTED or 204 NO CONTENT
      if (
        response.status === 201 ||
        response.status === 202 ||
        response.status === 204
      ) {
        dialog.close()
        window.location.reload()
        return
      }
      const html = await response.text()
      dialog.innerHTML = html
    }
  })
}

export function initModals() {
  document.body.addEventListener("click", (e) => {
    const target = e.target.closest("[data-toggle='ajaxmodal']")
    if (target) {
      e.preventDefault()
      if (target.getAttribute("href")) {
        showModalFromURL(target.href)
      }
    }
  })

  document.body.addEventListener("click", (e) => {
    const target = e.target.closest("[data-dismiss='modal']")
    if (target) {
      const dialog = target.closest("dialog")
      if (dialog) {
        dialog.close()
      }
    }
  })
}
