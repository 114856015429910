export function initAutosubmit() {
  document.body.addEventListener("change", (e) => {
    const form = e.target.closest("form[data-autosubmit]")
    if (!form) return

    if (form.method === "get") {
      const fd = new FormData(form)
      let params = new URLSearchParams()
      for (let [name, value] of fd) {
        if (value) params.append(name, value)
      }
      params.sort()
      params = params.toString()
      window.location.href = params ? `?${params}` : "."
    } else {
      form.requestSubmit()
    }
  })
}
