import { Fragment, h, render, createContext } from "preact"
import { useContext, useEffect, useState, useMemo } from "preact/hooks"
import { Router, Link, Route, useLocation, useRoute } from "wouter-preact"
import { useLocationProperty, useSearch } from "wouter-preact/use-location"

const MetaContext = createContext()
const StarredContext = createContext()

const identity = (data) => data
const useInitialData = (id, transform = identity) =>
  useMemo(() => {
    const el = document.getElementById(id)
    return transform(JSON.parse(el.textContent))
  }, [id, transform])

const Category = ({ category }) => {
  const href = `/category/${category.slug}/`
  const [isActive] = useRoute(href)
  return (
    <Link href={href}>
      <a
        class={`cell md-6 lg-3 box category ${
          isActive ? "category--active" : ""
        }`}
      >
        <div class="box__body">
          <h2>{category.name}</h2>
          <p>{category.shortDescription}</p>
        </div>
      </a>
    </Link>
  )
}

const Categories = ({ categories }) => {
  return (
    <div class="categories grid grid--gx grid--gy boxes">
      {categories.map((category) => (
        <Category key={category.slug} category={category} />
      ))}
    </div>
  )
}

const StarredIcon = ({ document }) => {
  const { starredDocuments, updateStarred } = useContext(StarredContext)
  const isStarred = starredDocuments.includes(document.id)

  return (
    <span class="document__star icon-wrap">
      <span
        class={`icon icon--${isStarred ? "star" : "unstar"}`}
        onClick={(e) => {
          e.preventDefault()
          updateStarred(isStarred ? "unstar" : "star", document.id)
        }}
      />
    </span>
  )
}

const Document = ({ document, showConstructionPhases }) => {
  const { constructionPhases, typesById } = useContext(MetaContext)
  const type = typesById[document.type]

  return (
    <div class={`cell md-6 lg-3 box document dt-accent-${type.accent}`}>
      <div class="box__body">
        <div class="box__title document__title">{document.title}</div>
        <p class="document__keywords">{document.keywords}</p>
        <div class="document__phases">
          {showConstructionPhases
            ? constructionPhases.map((phase) =>
                document.constructionPhases.includes(phase.id) ? (
                  <span
                    key={phase.id}
                    class="document__phase on"
                    title={phase.name}
                  >
                    {phase.siaId}
                  </span>
                ) : null,
              )
            : null}
        </div>
        <div class="document__updated-on">{document.updatedOnPretty}</div>
        <a class="document__download" href={`${document.url}?as_attachment=on`}>
          {document.isExternal ? <ExternalIcon /> : <DownloadIcon />}
        </a>
        <StarredIcon document={document} />
      </div>
      <a
        class="box__overlay-link"
        target="_blank"
        rel="noopener noreferrer"
        href={document.url}
      />
    </div>
  )
}

const Documents = ({ documents, showConstructionPhases }) =>
  documents && documents.length ? (
    <div class="grid grid--gx grid--gy boxes documents">
      {documents.map((document) => (
        <Document
          key={document.id}
          document={document}
          showConstructionPhases={showConstructionPhases}
        />
      ))}
    </div>
  ) : null

const TopicsAndDocuments = ({ documents, showConstructionPhases }) => {
  const d = documents.documentsByTopic
  if (!d || !d.length) {
    // FIXME might be loading.
    return <h3 class="documents__topic">Keine Dokumente gefunden</h3>
  }
  return (
    <div class="topics">
      {d.map(([topic, d]) => (
        <div class="topic" key={topic.id}>
          <h3 class="documents__topic">{topic.name}</h3>
          <Documents
            documents={d}
            showConstructionPhases={showConstructionPhases}
          />
        </div>
      ))}
    </div>
  )
}

const DocumentsApp = ({ base }) => {
  const meta = useInitialData("meta", (meta) => ({
    ...meta,
    typesById: Object.fromEntries(
      meta.documentTypes.map((type) => [type.id, type]),
    ),
    categoriesBySlug: Object.fromEntries(
      meta.categories.map((category) => [category.slug, category]),
    ),
  }))
  const { categories, constructionPhases, documentTypes } = meta

  const [documents, setDocuments] = useState(useInitialData("documents"))
  const [currentLocation, setCurrentLocation] = useLocation()
  const search = useSearch()
  const queryValue = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get("q"),
  )

  const loc = `${currentLocation}${search}`

  useEffect(() => {
    if (loc === documents.path) return

    let didCancel = false

    const doFetch = async () => {
      const response = await fetch("", {
        credentials: "same-origin",
        headers: { accept: "application/json" },
      })
      const data = await response.json()

      if (!didCancel) setDocuments(data)
    }

    setDocuments({})
    doFetch()

    return () => {
      didCancel = true
    }
  }, [currentLocation, window.location.search])

  const searchParams = new URLSearchParams(window.location.search)

  const applyFilter = (form) => {
    const fd = new FormData(form)
    const params = new URLSearchParams()
    for (let [name, value] of fd) {
      if (value) params.append(name, value)
    }
    params.sort()
    setCurrentLocation(`?${params.toString()}`)
  }

  const [starredDocuments, setStarredDocuments] = useState(() => {
    const el = document.getElementById("starred_document_ids")
    return JSON.parse(el.textContent).starredDocuments
  })
  const updateStarred = (action, id) => {
    const fd = new FormData()

    fd.append("action", action)
    fd.append("id", id)
    fetch(".", {
      credentials: "same-origin",
      method: "post",
      body: fd,
    })
      .then((r) => r.json())
      .then((data) => {
        setStarredDocuments(data.starredDocuments)
      })
  }

  return (
    <MetaContext.Provider value={meta}>
      <StarredContext.Provider value={{ starredDocuments, updateStarred }}>
        <Router base={base} key={base}>
          <div class="container">
            <div class="filter">
              <div class="filter__title">
                <h1 style="display:inline-block;margin-right:1rem">
                  Dokumente{" "}
                </h1>
                {
                  /* XXX Hack alert */ currentLocation !== "/documents/" ? (
                    <Link class="textlink" href="/">
                      Zurück zur Übersicht
                    </Link>
                  ) : null
                }
              </div>
              <div class="filter__form">
                <form
                  class="form form--filter"
                  method="get"
                  onSubmit={(e) => {
                    e.preventDefault()
                    const params = new URLSearchParams()
                    params.append("q", queryValue)
                    setCurrentLocation(`${base}/?${params.toString()}`)
                  }}
                >
                  <input
                    class="filter__search"
                    type="text"
                    name="q"
                    value={queryValue}
                    placeholder="In den Dokumenten suchen"
                    onInput={(e) => {
                      applyFilter(e.target.form)
                    }}
                  />
                </form>
              </div>
            </div>
            <Categories categories={categories} />
            <Route path="/">
              {!queryValue && documents.lastAccessedDocuments ? (
                <>
                  <h2 class="box-line">
                    <span class="box-line__text">Zuletzt verwendet</span>
                  </h2>
                  <Documents documents={documents.lastAccessedDocuments} />
                </>
              ) : null}
              {documents.documentsForSearch ? (
                <>
                  <h2 class="box-line">
                    <span class="box-line__text">Suchergebnisse</span>
                  </h2>
                  <Documents documents={documents.documentsForSearch} />
                </>
              ) : null}
            </Route>
            <Route path="/category/:slug">
              {(params) => {
                const category = meta.categoriesBySlug[params.slug]

                return (
                  <>
                    {/* <h2 class="documents__category">{category.name}</h2> */}
                    <form method="get">
                      <div class="types">
                        <strong class="types__title">
                          Dokumententyp wählen
                        </strong>
                        <span class="df__type dt-accent-0">
                          <input
                            type="radio"
                            name="type"
                            value=""
                            id="id_type_all"
                            checked={!searchParams.get("type")}
                            onInput={(e) => applyFilter(e.target.form)}
                          />
                          <label htmlFor="id_type_all">Alle</label>
                        </span>
                        {documentTypes.map((type) => {
                          const id = `id_type_${type.slug}`
                          return (
                            <span
                              key={type.slug}
                              class={`df__type dt-accent-${type.accent} ${
                                searchParams.get("type") ? "" : "checked"
                              }`}
                            >
                              <input
                                type="radio"
                                name="type"
                                value={type.slug}
                                id={id}
                                checked={type.slug === searchParams.get("type")}
                                onInput={(e) => {
                                  applyFilter(e.target.form)
                                }}
                              />
                              <label htmlFor={id}>{type.name}</label>
                            </span>
                          )
                        })}
                      </div>
                      {category.showConstructionPhases ? (
                        <div class="phases">
                          <strong class="phases__title">
                            SIA Bauphase wählen
                          </strong>
                          {constructionPhases.map((phase, idx) => {
                            const id = `phase_${phase.slug}`
                            return (
                              <span
                                key={phase.slug}
                                class="phase"
                                title={phase.name}
                              >
                                <input
                                  type="radio"
                                  name="phase"
                                  value={phase.slug}
                                  id={id}
                                  checked={
                                    phase.slug === searchParams.get("phase")
                                  }
                                  onClick={(e) => {
                                    e.target.checked =
                                      phase.slug !== searchParams.get("phase")
                                    applyFilter(e.target.form)
                                  }}
                                />
                                <label
                                  htmlFor={id}
                                  style={{ zIndex: 100 - idx }}
                                >
                                  <span class="phase__sia">{phase.siaId}</span>
                                  <Arrow />
                                </label>
                              </span>
                            )
                          })}
                        </div>
                      ) : null}
                    </form>
                    <TopicsAndDocuments
                      documents={documents}
                      showConstructionPhases={category.showConstructionPhases}
                    />
                  </>
                )
              }}
            </Route>
          </div>
        </Router>
      </StarredContext.Provider>
    </MetaContext.Provider>
  )
}

export function initDocuments() {
  document.querySelectorAll("[data-documents-root]").forEach((el) => {
    render(
      <DocumentsApp base={el.dataset.documentsRoot.replace(/\/+$/, "")} />,
      el,
    )
  })
}

const DownloadIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Download"
  >
    <path
      d="M8.03878 6.53876V-0.00561523H6.00015V6.61704L3.89878 4.51908L2.45605 5.95947L6.9881 10.4685L11.5672 5.89685L10.1245 4.45646L8.03878 6.53876Z"
      fill="currentColor"
    />
    <path
      d="M11.975 10.4216V11.956H2.03278V10.4216H-0.00585938V13.9913H14.0137V10.4216H11.975Z"
      fill="currentColor"
    />
  </svg>
)

const ExternalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -256 1850 1850"
    id="svg3025"
    version="1.1"
  >
    <defs id="defs3033" />
    <g transform="matrix(1,0,0,-1,30.372881,1426.9492)" id="g3027">
      <path
        d="M 1408,608 V 288 Q 1408,169 1323.5,84.5 1239,0 1120,0 H 288 Q 169,0 84.5,84.5 0,169 0,288 v 832 Q 0,1239 84.5,1323.5 169,1408 288,1408 h 704 q 14,0 23,-9 9,-9 9,-23 v -64 q 0,-14 -9,-23 -9,-9 -23,-9 H 288 q -66,0 -113,-47 -47,-47 -47,-113 V 288 q 0,-66 47,-113 47,-47 113,-47 h 832 q 66,0 113,47 47,47 47,113 v 320 q 0,14 9,23 9,9 23,9 h 64 q 14,0 23,-9 9,-9 9,-23 z m 384,864 V 960 q 0,-26 -19,-45 -19,-19 -45,-19 -26,0 -45,19 L 1507,1091 855,439 q -10,-10 -23,-10 -13,0 -23,10 L 695,553 q -10,10 -10,23 0,13 10,23 l 652,652 -176,176 q -19,19 -19,45 0,26 19,45 19,19 45,19 h 512 q 26,0 45,-19 19,-19 19,-45 z"
        id="path3029"
        style="fill:currentColor"
      />
    </g>
  </svg>
)

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 40"
    width="22"
    height="40"
  >
    <path d="M0 -1 L20 20 L0 41 z" fill="currentColor" />
    <path d="M0 -1 L20 20 L0 41" fill="none" stroke="#fff" stroke-width="2" />
  </svg>
)
