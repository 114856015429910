import { firstMatch } from "./utils.js"

export function initAnnouncements() {
  const mo = new MutationObserver((mutationList) => {
    mutationList.forEach((mutation) => {
      console.log({
        target: mutation.target,
        open: mutation.target.hasAttribute("open"),
      })
      if (!mutation.target.classList.contains("announcement--unread")) return

      if (!mutation.target.hasAttribute("open")) return

      const fd = new FormData()
      fd.append("status", "read")
      fetch(mutation.target.dataset.setStatus, {
        credentials: "same-origin",
        method: "post",
        body: fd,
      })
        .then((r) => r.json())
        .then((data) => {
          console.log({ data })
          mutation.target.classList.remove("announcement--unread")
        })
    })
  })
  document.querySelectorAll(".announcement").forEach((el) => {
    mo.observe(el, { attributes: true, attributeFilter: ["open"] })
  })

  document.body.addEventListener("click", (e) => {
    if (e.target.dataset.setStatus === "unread") {
      e.preventDefault()
      const details = e.target.closest("details")

      const fd = new FormData()
      fd.append("status", e.target.dataset.setStatus)
      fetch(details.dataset.setStatus, {
        credentials: "same-origin",
        method: "post",
        body: fd,
      })
        .then((r) => r.json())
        .then((data) => {
          console.log({ data })
          details.open = false
          details.classList.add("announcement--unread")
        })
    }
  })

  document.body.addEventListener("click", (e) => {
    if (
      e.target.dataset.setStatus === "starred" ||
      e.target.dataset.setStatus === "unstarred"
    ) {
      e.preventDefault()
      const details = e.target.closest("details")

      const fd = new FormData()
      fd.append("status", e.target.dataset.setStatus)
      fetch(details.dataset.setStatus, {
        credentials: "same-origin",
        method: "post",
        body: fd,
      })
        .then((r) => r.json())
        .then((data) => {
          console.log({ data })

          details.classList.toggle("announcement--starred", data.isStarred)
          details.classList.toggle("announcement--unstarred", !data.isStarred)
        })
    }
  })

  const open = firstMatch(window.location.hash, /(id[0-9]+)/)
  if (open) {
    const el = document.getElementById(open)
    if (el)
      setTimeout(() => {
        el.open = true
      }, 100)
  }
}
